<template>
  <SubletPage :categories="[reason]" title="Ange skäl för överlåtelsen">
    <OvForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      :block-documents-view="blockDocumentsView"
      :form-type="formType"
      v-on="$listeners"
    >
      <template v-if="isPrivateFlow">
        <FieldsWrapper
          ><div class="reason-subheading">
            Välj det skäl som passar bäst in på din situation
          </div>
          <div class="radio-btn-wrapper">
            <!-- <RadioButton
          v-for="ovReason in ovReasons"
          :key="ovReason.value"
          v-model="reason.type"
          :radio-btn-label="ovReason.label"
          :radio-btn-value="ovReason.value"
          :selected-value="reason.type"
          :is-disabled="editingDisabled"
          @change="handleRadioBtnSelected"
        /> -->
            <div
              v-for="ovReason in ovReasons"
              :key="ovReason.value"
              class="ov-choice"
            >
              <input
                :id="ovReason.value"
                v-model="reason.type"
                type="radio"
                class="ov-choice-input"
                :value="ovReason.value"
                @input="handleRadioBtnSelected"
              />
              <label :for="ovReason.value" class="ov-choice-label">{{
                ovReason.label
              }}</label>
            </div>
          </div></FieldsWrapper
        >

        <FieldsWrapper v-if="reasonType !== '' && isPrivateFlow">
          <Fieldset>
            <OvInput
              v-model="transferDate"
              label="Önskat överlåtelsedatum"
              description="Ange datum"
              type="date"
              :min="getDateAMonthFromCurrentDate(1)"
            />
            <Select
              v-model="selectedRelationship"
              :is-disabled="editingDisabled"
              :selected-option="selectedRelationship"
              title="Relation till tilltänkta hyresgästen"
              :description="`Ange din relation till tilltänkta hyresgäst`"
              :options="Object.values(relationships)"
            />
          </Fieldset>
          <OvInput
            v-model="reason.text"
            :maxlength="`${maxReasonLength}`"
            class="other-reason-textarea"
            name="other-reason"
            type="textarea"
            label="Beskriv kortfattat situationen"
            :description="`Max ${maxReasonLength} tecken`"
            placeholder="..."
            :show-green-checkmark="false"
          />
        </FieldsWrapper>
      </template>
      <template v-else>
        <FieldsWrapper>
          <Fieldset v-if="isPrivateFlow">
            <OvInput
              v-model="transferDate"
              label="Önskat överlåtelsedatum"
              description="Ange datum"
              type="date"
              :min="getDateAMonthFromCurrentDate(1)"
            />
            <Select
              v-model="selectedRelationship"
              :selected-option="selectedRelationship"
              title="Relation till tilltänkta hyresgästen"
              :description="`Ange din relation till tilltänkta hyresgäst`"
              :options="Object.values(relationships)"
            />
          </Fieldset>
          <Fieldset v-else
            ><OvInput
              v-model="transferDate"
              label="Önskat överlåtelsedatum"
              description="Ange datum"
              type="date"
              :min="getDateAMonthFromCurrentDate(1)" /><Checkbox
              v-model="isFullTransfer"
              class="r-checkbox"
              name="isFullTransfer"
              description="Hela verksamheten ska överlåtas"
              :checked="isFullTransfer"
              :is-disabled="!applicationIsEditable"
              @change="isFullTransfer = !isFullTransfer"
          /></Fieldset>
          <OvInput
            v-model="reason.text"
            :maxlength="`${maxReasonLength}`"
            class="other-reason-textarea"
            name="other-reason"
            type="textarea"
            :label="
              isPrivateFlow
                ? 'Beskriv kortfattat situationen'
                : 'Ange skäl för överlåtelsen samt beskriv den verksamheten som ska bedrivas i lokalen'
            "
            :description="`Max ${maxReasonLength} tecken`"
            placeholder="..."
            :show-green-checkmark="false"
          />
        </FieldsWrapper>
      </template>
    </OvForm>
  </SubletPage>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePostalCode,
  validatePersonalNumber
} from '@/utils/input-validations';
import { getDateAMonthFromCurrentDate } from '@/utils/common';
import OvInput from '@/components/OvInput.vue';
import Fieldset from '@/components/Fieldset.vue';
import SubletPage from '@/components/SubletPage.vue';
import OvForm from '@/components/OvForm.vue';
import FieldsWrapper from '@/components/FieldsWrapper.vue';
import Select from '@/components/Select.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'Reason',
  components: {
    OvInput,
    Fieldset,
    SubletPage,
    OvForm,
    FieldsWrapper,
    Select,
    Checkbox
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      radioBtnSelected: false,
      validEmail,
      validateNumber,
      validatePostalCode,
      validatePersonalNumber,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      getDateAMonthFromCurrentDate,
      formExpanded: false,
      documentsInfoTextVisible: false,
      formType: 'reason',
      ovReasons: [
        {
          label: 'Separation/skilsmässa',
          value: 'separation'
        },
        {
          label: 'Hälsoskäl',
          value: 'halsa'
        },
        {
          label: 'Dödsfall',
          value: 'dodsfall'
        },
        {
          label: 'Ekonomiska skäl',
          value: 'ekonomi'
        },
        {
          label: 'Flytt till äldreboende',
          value: 'aldreboende'
        },
        {
          label: 'Studentlägenhet',
          value: 'student'
        },
        {
          label: 'Annat',
          value: 'annat'
        }
      ],
      relationships: {
        '': 'Inget val',
        barn: 'Barn',
        makeMaka: 'Make/Maka',
        sambo: 'Sambo',
        slakting: 'Släkting',
        syskon: 'Syskon',
        annat: 'Annat'
      },
      maxReasonLength: 500
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'ovApplication/applicationIsEditable',
      rejectedCategories: 'ovApplication/rejectedCategories',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    reverseRelationships() {
      return Object.fromEntries(
        Object.entries(this.relationships).map(([key, value]) => [value, key])
      );
    },
    isFullTransfer: {
      get() {
        return !!this.reason?.isFullTransfer || false;
      },
      set(newValue) {
        this.$set(this.reason, 'isFullTransfer', !!newValue);
      }
    },
    selectedRelationship: {
      get() {
        const value = this.relationships[this.reason.relationship];
        return value || '';
      },
      set(value) {
        const key = this.reverseRelationships[value];
        this.reason.relationship = key;
      }
    },
    reasonCategoryRejected() {
      return this.rejectedCategories.includes('reason');
    },
    editingDisabled() {
      return !this.applicationIsEditable && !this.reasonCategoryRejected;
    },
    reasonTypeIsOv() {
      return [
        'halsa',
        'separation',
        'dodsfall',
        'ekonomi',
        'aldreboende',
        'annat',
        'student'
      ];
    },
    reason: {
      get() {
        return this.application?.reason;
      },
      set() {
        this.$emit();
      }
    },
    movingAbroad: {
      get() {
        return this.reason?.movingAbroad || false;
      },
      set(newValue) {
        if (this.reason) {
          this.$set(this.reason, 'movingAbroad', newValue);
        }
      }
    },
    propertyOwner: {
      get() {
        return this.reason?.propertyOwner || false;
      },
      set(newValue) {
        if (this.reason) {
          this.$set(this.reason, 'propertyOwner', newValue);
        }
      }
    },
    transferDate: {
      get() {
        return this.getDateHelper(this.reason.transferDate);
      },
      set(value) {
        this.reason.transferDate = this.setDateHelper(value);
      }
    },
    reasonType() {
      return this.reason?.type || '';
    },
    ahInfoText() {
      let text = '';
      if (this.reasonType === 'sambo') {
        text =
          'För provsambo är den tillåtna tidsperioden för uthyrning upp till och med ett år.';
      } else {
        text =
          'Hyresvärden godkänner vanligast uthyrning ett år åt gången, men kan ibland tillåta uthyrning under längre perioder.';
      }
      text +=
        ' När du gör din ansökan är det startdatum och slutdatum du väljer endast önskemål. Tänk på att du behöver hyra ut i minst tre månader för att få en godkänd ansökan.';
      return text;
    },
    blockDocumentsView() {
      const pathname = window.location.pathname;
      const parts = pathname.split('/');
      const category = parts.pop();

      if (category === 'skal') {
        if (this.isPrivateFlow) {
          return !this.reasonType;
        } else {
          return !this.reason?.text;
        }
      }

      return false;
    },
    titleName() {
      return `Namn på ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    titlePhone() {
      return `Telefon till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    titleEmail() {
      return `Email till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    descriptionName() {
      return `Ange för- och efternamn på ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    descriptionPhone() {
      return `Ange telefonnummer till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    descriptionEmail() {
      return `Ange e-postadress till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    expandBtnText() {
      if (this.formExpanded) {
        return this.reason?.housingType === 'villa'
          ? 'Ta bort ägare'
          : 'Ta bort andra innehavare';
      } else {
        return this.reason?.housingType === 'villa'
          ? 'Fler ägare? Klicka för att lägga till'
          : 'Fler innehavare? Klicka för att lägga till';
      }
    }
  },
  mounted() {
    if (
      this.reason.secondHouseOwner ||
      this.reason.secondOwnerEmail ||
      this.reason.secondOwnerPhone
    ) {
      this.formExpanded = true;
    }
  },
  methods: {
    sweDateStr(value = null) {
      return new Date(value).toLocaleDateString('sv-SE');
    },
    setDateHelper(value) {
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (value.match(regEx)) {
        return this.sweDateStr(value);
      }
      return this.sweDateStr();
    },
    getDateHelper(value) {
      return value ? this.sweDateStr(value) : '';
    },
    handleRadioBtnSelected(event) {
      const value = event.target.value;
      this.reason.type = value;
      this.emitEditing();
    },
    handleSubRadioBtnSeleted(value) {
      this.reason.housingType = value;
      this.emitEditing();
    },
    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    handleButtonClick() {
      this.formExpanded = !this.formExpanded;

      if (!this.formExpanded) {
        this.reason.secondHouseOwner = '';
        this.reason.secondOwnerEmail = '';
        this.reason.secondOwnerPhone = '';
      }

      this.emitEditing();
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    },
    handlePropertyOwnerToggle() {
      this.propertyOwner = !this.propertyOwner;
    },
    handleMovingAbroadToggle() {
      this.movingAbroad = !this.movingAbroad;
    }
  }
};
</script>

<style lang="scss" scoped>
.reason-subheading {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--color-dark);
}

.radio-btn-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ov-choice-label {
  display: block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px solid;
  border-color: var(--color-dark);
  color: var(--color-dark);
  background-color: #fff;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.ov-choice-input {
  display: none;
  &:checked + .ov-choice-label {
    opacity: 1;
    background-color: var(--color-blue);
    border-color: var(--color-blue);
    color: #fff;
  }
}

.r-checkbox {
  margin-bottom: 0;
  @include mobile {
    margin-bottom: 4.8rem;
  }
}
</style>
