var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"expand"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"before-leave":_vm.beforeLeave,"leave":_vm.leave}},[(_vm.companyDetails && !!Object.keys(_vm.companyDetails).length)?_c('div',{staticClass:"company-container"},[(
        _vm.companyDetails.ceo ||
        _vm.companyDetails.chairman ||
        _vm.companyDetails.boardMember
      )?_c('Fieldset',[_c('OvInput',{attrs:{"value":_vm.companyDetails.name,"label":"Namn","disabled":true}}),(_vm.companyDetails.ceo)?_c('OvInput',{attrs:{"value":_vm.companyDetails.ceo,"label":"Verkställande direktör","disabled":true}}):(_vm.companyDetails.chairman)?_c('OvInput',{attrs:{"value":_vm.companyDetails.chairman,"label":"Ordförande","disabled":true}}):(_vm.companyDetails.boardMember)?_c('OvInput',{attrs:{"value":_vm.companyDetails.boardMember,"label":"Ledamot","disabled":true}}):_vm._e()],1):_vm._e(),_c('Fieldset',[_c('OvInput',{attrs:{"value":_vm.companyDetails.name,"label":"Namn","disabled":true}}),_c('OvInput',{attrs:{"value":new Date(_vm.companyDetails.registrationDate).toLocaleDateString(
            'sv-SE'
          ),"label":"Registreringsdatum","disabled":true}})],1),_c('OvInput',{attrs:{"value":_vm.companyDetails.address,"label":"Adress","disabled":true}}),_vm._l((_vm.filteredSnis),function(sni,i){return _c('OvInput',{key:sni.kod,attrs:{"value":`${sni.kod} ${sni.klartext}`,"label":`SNI-branch (${i + 1})`,"disabled":true}})}),_c('OvInput',{attrs:{"value":_vm.companyDetails.activities,"type":"textarea","disabled":true,"label":"Verksamhet & ändamål"}})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }