<template>
  <SubletPage title="Ladda upp filer">
    <CollapseCard
      is-primary
      initially-open
      class="mb-4"
      :closed-identifier="dynamicClosedIdentifier"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <div class="info-text-container">
          <p v-html="dynamicInfoTitle"></p>
          <p v-html="dynamicInfoText"></p>
          <p v-html="dynamicHousingTypeInfoText"></p>
          <div class="q-and-a-container">
            <h3>Frågor och svar</h3>
            <div>
              <h3>Hur ska ett andrahandshyresavtal se ut?</h3>
              <p>
                Vi rekommenderar att du använder Fastighetsägarnas mall för
                <a
                  href="https://app.fastdok.se/external/contract-create/174"
                  target="_blank"
                  >andrahandsuthyrning</a
                >. Andrahandshyresavtalet ska vara underskrivet med
                namnteckningar. Tänk på att du endast får göra tillägg på din
                hyra om den hyrs ut möblerad och att eventuella tillägg ska
                specificeras.
              </p>
            </div>
            <div v-for="item in dynamicReasonTypeFAQ" :key="item.question">
              <h3>{{ item.question }}</h3>
              <p v-html="item.answer"></p>
            </div>
            <div v-for="item in dynamicHousingTypeFAQ" :key="item.question">
              <h3>{{ item.question }}</h3>
              <p v-html="item.answer" />
            </div>
          </div>
        </div>
        <br />
        <p>Tänk på att:</p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          <strong>Tips:</strong> För att fota dokument på bästa sätt
          rekommenderar vi att du använder dig av en app som scannar av
          dokument. Exempel på appar som fungerar bra till både iOS och Android
          är Adobe Scan och Genius Scan, båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a
            :href="`mailto:${getSupportAddress}?subject=Andra%20Hand`"
            target="_blank"
          >
            {{ getSupportAddress }}</a
          >
        </p>
      </template>
    </CollapseCard>
    <Summary
      v-if="!applicationIsEditable && !documentsRejected"
      category="DOCUMENTS"
      class="documents-view-wrapper"
      :show-change-btn="false"
    >
      <SummaryBody sub-heading="Uppladdade filer" class="documents-subheading">
        <SubletDocumentsGrid
          v-if="documentsForSelectedReason.length > 0"
          :documents="documentsForSelectedReason"
          :application-id="application.id"
        />
      </SummaryBody>
    </Summary>
    <div v-else class="upload-area">
      <p>* <i>Obligatoriskt att ladda upp</i></p>
      <SubletDocumentsUpload
        v-for="{ category, required } in activeCategoriesForUser"
        :key="category"
        :label="getLabel(category, required)"
        :description="getDescription(category)"
        :document-category="category"
        :documents="getFilteredDocuments(category)"
      />
    </div>
    <BaseButton
      class="go-to-next-category-btn"
      type="button"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-disabled="!applicationIsEditable && !isMobile"
      :is-small="isMobile"
      @click="goToNextCategory($router)"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Gå vidare
      </BaseIconText>
    </BaseButton>
  </SubletPage>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SubletPage from '@/components/SubletPage.vue';
import SubletDocumentsUpload from '@/components/SubletDocumentsUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import icons from '@/utils/icon-constants';
import svgs from '@/utils/icon-constants';
import SubletDocumentsGrid from '@/components/SubletDocumentsGrid.vue';

export default {
  name: 'Documents',

  components: {
    SubletPage,
    SubletDocumentsUpload,
    CollapseCard,
    BaseButton,
    BaseIconText,
    Summary,
    SummaryBody,
    SubletDocumentsGrid
  },
  data() {
    return {
      icons,
      svgs
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    ...mapGetters({
      getSupportAddress: 'app/getSupportAddress',
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      rejectedCategories: 'subletApplication/rejectedCategories',
      requiredDocuments: 'subletApplication/requiredDocuments',
      noneRequiredDocuments: 'subletApplication/noneRequiredDocuments'
    }),
    documentsRejected() {
      return (
        this.rejectedCategories.includes('documents') ||
        this.rejectedCategories.includes('computedDocumentsGroups')
      );
    },
    categories() {
      const categories = Object.keys(this.computedDocumentsGroups).map(key => ({
        rejectReason: this.computedDocumentsGroups[key].rejectReason,
        status: this.computedDocumentsGroups[key].status,
        category: key
      }));
      return [...this.documents, ...categories];
    },
    computedDocumentsGroups() {
      return this.application?.computedDocumentsGroups || {};
    },
    documents() {
      return this.application?.documents || [];
    },
    reason() {
      return this.application?.reason;
    },
    reasonType() {
      return this.application?.reason?.type;
    },
    housingType() {
      return this.application?.reason?.housingType;
    },
    dynamicClosedIdentifier() {
      switch (this.reasonType) {
        case 'sambo':
          return 'cohabitant_card_closed';
        case 'arbete':
          return 'work_info_card_closed';
        case 'studie':
          return 'study_info_card_closed';
        case 'utlandsvistelse':
          return 'abroad_info_card_closed';
        case 'other':
          return 'other_info_card_closed';
        default:
          return 'default_info_card_closed';
      }
    },
    dynamicInfoTitle() {
      switch (this.reasonType) {
        case 'sambo':
          return 'Här laddar du upp dokument som krävs för provsambo.';
        case 'arbete':
          return 'Här laddar du upp dokument som krävs för arbete på annan ort eller utomlands.';
        case 'studie':
          return 'Här laddar du upp dokument som krävs för studie på annan ort eller utomlands.';
        case 'utlandsvistelse':
          return 'Här laddar du upp dokument som krävs för din utlandsvistelse.';
        case 'other':
          return 'Här laddar du upp dokument.';
        default:
          return 'Här laddar du upp dokument.';
      }
    },
    dynamicInfoText() {
      const warrantTemplateLink = `
          <a
              style="text-decoration: underline;"
              onmouseover="this.style.color='#409fff';"
              onmouseout="this.style.color='#878b9d';"
              href="https://bytesansokan-pdfs-se-prod.s3.eu-north-1.amazonaws.com/public/Fullmakt%20utlandsvistelse%20andrahandsuthyrning.pdf "
              target="_blank"
          >från vår mall.</a>`;

      let familjebevisAndUCReq = false;
      let familjebevisAndUCText = '';

      if (
        this.getDocumentsPerCategory.includes('familjebevis') &&
        this.getDocumentsPerCategory.includes('uc')
      ) {
        familjebevisAndUCReq = true;
        familjebevisAndUCText =
          ' Hyresvärden kräver även familjebevis & UC för dig som ska hyra ut och personen du ska provbo med.';
      }

      switch (this.reasonType) {
        case 'sambo':
          return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden 
          ${familjebevisAndUCReq ? 'familjebevis & UC' : 'familjebevis'} för dig som ska hyra ut och personen du ska provbo med.`;
        case 'arbete':
          if (this.reason?.movingAbroad) {
            return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du 
            tillhandahåller anställningsbevis.${familjebevisAndUCText} Vid arbete utomlands 
            behövs även dokumentation som bevisar din utresa ur Sverige, samt en fullmakt som gör att en annan person kan agera i ditt 
            intresse (OBS! Detta får ej vara andrahandshyresgästen). För att skapa en fullmakt kan ni utgå ${warrantTemplateLink}`;
          } else {
            return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver 
            hyresvärden att du tillhandahåller anställningsbevis.${familjebevisAndUCText}`;
          }
        case 'studie':
          if (this.reason?.movingAbroad) {
            return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du 
              tillhandahåller en del olika dokument. Vid studier utomlands behövs även dokumentation som bevisar din utresa ur 
              Sverige, samt en fullmakt som gör att en annan person kan agera i ditt intresse (OBS! Detta får ej vara andrahandshyresgästen).${familjebevisAndUCText} 
              För att skapa en fullmakt kan ni utgå ${warrantTemplateLink}`;
          } else {
            return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du 
            tillhandahåller en del olika dokument.${familjebevisAndUCText}`;
          }
        case 'utlandsvistelse':
          return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du 
            tillhandahåller en del olika dokument. Vid utlandsvistelse behövs även dokumentation som bevisar din utresa ur Sverige, 
            samt en fullmakt som gör att en annan person kan agera i ditt intresse (OBS! Detta får ej vara andrahandshyresgästen). 
            För att skapa en fullmakt kan ni utgå ${warrantTemplateLink}${familjebevisAndUCText}`;
        case 'other':
          if (this.reason?.movingAbroad) {
            return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du 
              tillhandahåller en del olika dokument. <u>Vid utlandsvistelse behövs även dokumentation som bevisar din utresa 
                ur Sverige</u>, samt en <b>fullmakt</b> som gör att en annan person kan agera i ditt intresse (OBS! Detta får ej 
                vara andrahandshyresgästen). För att skapa en fullmakt kan ni utgå ${warrantTemplateLink}${familjebevisAndUCText}`;
          }
          return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden dokument 
          från dig som ska hyra ut.${familjebevisAndUCText} Dessa dokument ska styrka den anledning du angett i steget innan.`;
        default:
          return `För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden dokument 
          från dig som ska hyra ut.${familjebevisAndUCText} Dessa dokument ska styrka den anledning du angett i steget innan.`;
      }
    },
    dynamicHousingTypeInfoText() {
      if (this.reasonType === 'sambo') {
        switch (this.housingType) {
          case 'hyresratt':
            return 'Du behöver även ladda upp dokument där det framgår vem som står på kontraktet.';
          case 'bostadsratt':
            return 'Du behöver även ladda upp köpekontrakt för BRF.';
          case 'villa':
            return 'Du behöver även ladda upp köpekontrakt där det framgår vem som äger villan dit ni ska flytta.';
          default:
            return '';
        }
      }
      return null;
    },
    dynamicReasonTypeFAQ() {
      const familyCertLink = `Beställning av familjebevis gör du enklast
          <a
              style="text-decoration: underline;"
              onmouseover="this.style.color='#409fff';"
              onmouseout="this.style.color='#878b9d';"
              href="https://skatteverket.se/privat/folkbokforing/bestallpersonbevis/allapersonbevis.4.361dc8c15312eff6fdb20.html?mode=list&code=1209"
              target="_blank"
          >här</a>
          (länk till Skatteverket).`;

      const reasonTypeFAQ = [];

      if (this.getDocumentsPerCategory.includes('familjebevis')) {
        reasonTypeFAQ.push(
          {
            question:
              'Vad är ett familjebevis och varför måste jag ladda upp ett sådant?',
            answer:
              'Ett familjebevis är en viss typ av personbevis som inte bara visar folkbokföringsadress utan även eventuella barn och andra familjeförhållanden. Familjebeviset skickas till din folkbokföringsadress via post och går ej att hämta online. Körkort, ID-kort samt pass gäller ej som personbevis. ' +
              familyCertLink
          },
          {
            question: 'För vem ska jag ladda upp familjebevis?',
            answer:
              'Du behöver ladda upp familjebevis för dig som ska hyra ut och för din sambo. Tänk på att familjebeviset får ej vara äldre än 3 månader.'
          }
        );
      }

      if (this.getDocumentsPerCategory.includes('uc')) {
        reasonTypeFAQ.push({
          question: 'Hur laddar jag upp UC?',
          answer: `Du laddar enkelt ned en kreditupplysning via tjänsten
                <a
                  href="http://www.minuc.se/min-upplysning/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Min upplysning</a
                >
                för 39kr/person. Observera att kreditupplysningen måste inhämtas
                personligen från MinUC. <strong>Inga andra kreditupplysningar godkänns.</strong>`
        });
      }

      switch (this.reasonType) {
        case 'arbete':
          reasonTypeFAQ.push([
            {
              question: 'Varför måste jag ladda upp ett anställningsbevis?',
              answer: 'För att säkerställa att skälet du angett är giltigt.'
            }
          ]);

          if (this.reason.movingAbroad) {
            reasonTypeFAQ.push({
              question:
                'Vad ska jag ladda upp för att styrka min utresa ur Sverige?',
              answer:
                'Det kan vara resehandlingar som flygbiljett eller visum för landet dit du kommer att flytta för att genomföra ditt arbete.'
            });
          }

          break;
        case 'studie':
          reasonTypeFAQ.push(
            {
              question: 'Varför måste jag ladda upp ett intyg från CSN?',
              answer: 'För att säkerställa att skälet du valt är giltigt.'
            },
            {
              question: 'Jag kommer inte att ansöka om CSN, vad gör jag då?',
              answer:
                'Om du väljer att inte ansöka om CSN (Centrala Studiestödsnämnden) för finansiellt stöd under dina studier, måste du istället tillhandahålla andra dokument som antagningsbevis för att styrka din antagning till den skola eller institut där du planerar att genomföra dina studier. '
            },
            {
              question: 'Varför måste jag ladda upp ett registreringsintyg?',
              answer:
                'För att säkerställa att du faktiskt kommer att genomföra dina studier.'
            },
            {
              question:
                'När måste jag senast ha laddat upp mitt registreringsintyg?',
              answer:
                'Du ska ladda upp ditt registreringsintyg så fort du registrerat dig på den skola / institut där du kommer att genomföra dina studier. Du kan fortfarande skicka in din ansökan utan att ha laddat upp ett registreringsintyg men kan komma att behöva komplettera med detta i efterhand.'
            }
          );

          if (this.reason.movingAbroad) {
            reasonTypeFAQ.push({
              question:
                'Vad ska jag ladda upp för att styrka min utresa ur Sverige?',
              answer:
                'Det kan vara resehandlingar som flygbiljett eller visum för landet dit du kommer att flytta för att genomföra dina studier.'
            });
          }

          break;
        case 'utlandsvistelse':
          reasonTypeFAQ.push({
            question:
              'Vad ska jag ladda upp för att styrka min utresa ur Sverige?',
            answer:
              'Det kan vara resehandlingar som flygbiljett eller visum för landet dit du kommer att flytta för din utlandsvistelse.'
          });
          break;
        case 'other':
          reasonTypeFAQ.push({
            question: 'Vad ska jag ladda upp för dokument?',
            answer:
              'Dokumenten bör tydligt styrka den angivna orsaken. <br/> <strong>Ex.</strong> Du har gett anledningen för att hyra ut din lägenhet som <i>"Flytta till landställe."</i> <br/> - då laddar du upp ägandebevis eller köpekontrakt för ditt landställe.'
          });
          if (this.reason.movingAbroad) {
            reasonTypeFAQ.push({
              question:
                'Vad ska jag ladda upp för att styrka min utresa ur Sverige?',
              answer:
                'Det kan vara resehandlingar som flygbiljett eller visum för landet dit du kommer att flytta för din utlandsvistelse.'
            });
          }
      }
      return reasonTypeFAQ;
    },
    dynamicHousingTypeFAQ() {
      const housingTypeFAQ = [];

      switch (this.housingType) {
        case 'hyresratt':
          housingTypeFAQ.push({
            question:
              'Varför måste jag ladda upp dokument med information om vem som står på kontraktet?',
            answer:
              'Hyresvärden behöver kunna säkerställa vem det är som har förstahandskontraktet till boendet som ni kommer att provbo i.'
          });
          break;
        case 'bostadsratt':
          housingTypeFAQ.push({
            question:
              'Varför måste jag ladda upp köpekontrakt för bostadsrättsföreningen dit vi ska flytta?',
            answer:
              'Hyresvärden behöver kunna säkerställa vem det är som äger boendet som ni kommer att provbo i.'
          });
          break;
        case 'villa':
          housingTypeFAQ.push({
            question:
              'Varför måste jag ladda upp köpekontrakt från ägaren av villan dit vi ska flytta?',
            answer:
              'Hyresvärden behöver kunna säkerställa vem det är som äger boendet som ni kommer att provbo i.'
          });
      }
      return housingTypeFAQ;
    },
    getDocumentsPerCategory() {
      if (!this.reason || !this.reason.type) {
        return [];
      }

      return [...this.requiredDocuments, ...this.noneRequiredDocuments];
    },
    documentsForSelectedReason() {
      const documentsToShow = this.getDocumentsPerCategory;
      const filteredDocuments = this.documents.filter(doc =>
        documentsToShow.includes(doc.category)
      );

      return filteredDocuments;
    },
    activeCategoriesForUser() {
      if (!Object.keys(this.computedDocumentsGroups).length) {
        return null;
      }
      return Object.entries(this.computedDocumentsGroups)
        .map(([key, props]) => ({
          category: key,
          required: props.required
        }))
        .sort((a, b) => {
          if (b.required !== a.required) {
            return b.required - a.required;
          }

          return a.category.localeCompare(b.category);
        });
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'subletApplication/goToNextCategory'
    }),
    getFilteredDocuments(category) {
      if (!category || this.documents.length === 0) {
        return [];
      }

      const currDocumentCat = this.documents.filter(
        document => document.category === category
      );

      return currDocumentCat;
    },
    getLabel(category, required) {
      const reqSymbol = required ? '*' : '';
      let text = '';

      switch (category) {
        case 'andrahandshyresavtal':
          text = 'Här laddar du upp ert andrahandshyresavtal';
          break;
        case 'csn-intyg':
          text = 'Här laddar du upp intyg från CSN';
          break;
        case 'familjebevis':
          text = 'Här laddar du upp ditt familjebevis';
          break;
        case 'fullmakt':
          text = 'Här laddar du upp fullmakt';
          break;
        case 'resehandling':
          text = 'Här laddar du upp bevis på din utresa ur Sverige';
          break;
        case 'registreringsintyg':
          text = 'Här laddar du upp registreringsintyg';
          break;
        case 'övrigt':
          text = 'Här laddar du upp övriga dokument som styrker din anledning';
          break;
        case 'other':
          text = 'Här laddar du upp dokument som styrker din anledning';
          break;
        case 'anställningsbevis':
          text = 'Här laddar du upp anställningsbevis';
          break;
        case 'kontrakt':
          text = 'Här laddar du upp ditt nuvarande hyresavtal';
          break;
        case 'medlemsregister':
          text = 'Här laddar du upp köpekontrakt för BRF';
          break;
        case 'uc':
          text = 'Här laddar du upp din UC';
          break;
        case 'köpekontrakt':
          text = 'Här laddar du upp köpekontrakt';
          break;
        default:
          text = category;
      }

      text = `${text}${reqSymbol}`;
      return text;
    },
    getDescription(category) {
      switch (category) {
        case 'familjebevis':
          return 'Även på den personen du ska provbo med';
        case 'csn-intyg':
          return 'Kommer du inte att ansöka om CSN? Då kan du istället ladda upp ditt antagningsbevis.';
        case 'anställningsbevis':
          return 'Anställningsavtalet behöver styrka den nya orten som du ska arbeta på.';
        case 'köpekontrakt':
          return 'Här laddar du upp köpekontrakt där det framgår vem som äger villan dit ni ska flytta.';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  &:hover {
    color: var(--color-blue);
  }
}
.info-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .q-and-a-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }
}

.upload-area > * {
  margin-bottom: 3.2rem;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.go-to-next-category-btn {
  margin-top: 4.8rem;
}

.documents-subheading {
  @include mobile {
    display: flex;
    align-items: center !important;
    width: 100%;
  }
}
</style>
