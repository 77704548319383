<template>
  <SubletPage
    v-if="currentTenants"
    :categories="currentTenants"
    :title="
      isPrivateFlow ? 'Om dig som ska överlåta' : 'Nuvarande firmatecknare'
    "
  >
    <OvForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      :form-type="formType"
      v-on="$listeners"
    >
      <FieldsWrapper v-if="!isPrivateFlow">
        <OvOrganizationSearch v-model="currentTenants[0].organizationNumber" />
        <CompanyDetails :company-details="activeCompanyDetails" />
      </FieldsWrapper>
      <FieldsWrapper>
        <template v-if="isPrivateFlow">
          <OvInput
            v-model="currentTenants[0].name"
            label="Namn"
            placeholder="Namn"
            description="Ange ditt för- och efternamn"
            autocomplete="given-name"
          />
          <Fieldset>
            <OvInput
              v-model="currentTenants[0].email"
              label="E-post"
              placeholder="E-post"
              description="Ange din e-postadress"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              autocomplete="email"
            />
            <OvInput
              v-model="currentTenants[0].phone"
              label="Telefonnummer"
              placeholder="Telefonnummer"
              description="Ange ditt telefonnummer"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="tel"
            />
          </Fieldset>
        </template>
        <template v-else>
          <Fieldset>
            <OvInput
              v-model="currentTenants[0].name"
              label="Behörig firmatecknare"
              placeholder="Behörig firmatecknare"
              description="Ange behörig firmatecknare"
              autocomplete="given-name"
            />
            <OvInput
              v-model="currentTenants[0].ssn"
              label="Firmatecknarens personnummer"
              placeholder="Firmatecknarens personnummer"
              description="Ange Firmatecknarens personnummer"
              :validation="validateSsn"
              :error-text="ssnErrorText"
            />
          </Fieldset>
          <Fieldset>
            <OvInput
              v-model="currentTenants[0].email"
              label="Firmatecknarens e-post"
              placeholder="E-post"
              description="Ange firmatecknarens e-postadress"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              autocomplete="email"
            />
            <OvInput
              v-model="currentTenants[0].phone"
              label="Firmatecknarens telefonnummer"
              placeholder="Telefonnummer"
              description="Ange firmatecknarens telefonnummer"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="tel"
            />
          </Fieldset>
          <OvInput
            v-model="currentTenants[0].address"
            label="Firmatecknarens adress"
            placeholder="Firmatecknarens adress"
            description="Ange firmatecknarens adress"
          />
          <Fieldset>
            <OvPostalCodeSearchBar
              v-model="currentTenants[0].postalCode"
              :class="{ autocomplete: postalCodeCompleted }"
              label="Firmatecknarens postnummer"
              placeholder="Firmatecknarens postnummer"
              description="Ange firmatecknarens postnummer"
              :validation="validatePostalCode"
              error-text="Postnummer måste anges med endast 5 siffror"
              @input="postalCodeCompleted = false"
              @dropdownMatch="postalCodeDropdownMatch($event, 0)"
            />
            <OvInput
              v-model="currentTenants[0].city"
              :class="{ autocomplete: postalCodeCompleted }"
              label="Firmatecknarens ort"
              placeholder="Firmatecknarens ort"
              description="Ange firmatecknarens ort"
              @input="postalCodeCompleted = false"
            />
          </Fieldset>
        </template>
      </FieldsWrapper>
      <FieldsWrapper>
        <ExpandableForm
          no-margin
          :disabled="!applicationIsEditable && !currentTenantsCategoryRejected"
          :btn-text="
            formExpanded
              ? `Ta bort ${isPrivateFlow ? 'medboende' : 'firmatecknare'}`
              : `Lägg till ${isPrivateFlow ? 'medboende' : 'firmatecknare'}`
          "
          :form-expanded="formExpanded"
          :is-personal-info="true"
          @click="handleButtonClick"
        >
          <template v-if="currentTenants[1]">
            <div class="top-container">
              <h1 ref="expandableForm">
                {{ isPrivateFlow ? 'Medboende' : 'Firmatecknare 2' }}
              </h1>
            </div>
            <template v-if="isPrivateFlow">
              <OvInput
                v-model="currentTenants[1].name"
                label="Namn"
                placeholder="Namn"
                description="Ange ditt för- och efternamn"
                autocomplete="given-name"
              />
              <Fieldset>
                <OvInput
                  v-model="currentTenants[1].email"
                  label="E-post"
                  placeholder="E-post"
                  description="Ange din e-postadress"
                  type="email"
                  :validation="validEmail"
                  error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
                  autocomplete="email"
                />
                <OvInput
                  v-model="currentTenants[1].phone"
                  label="Telefonnummer"
                  placeholder="Telefonnummer"
                  description="Ange ditt telefonnummer"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="tel"
                />
              </Fieldset>
            </template>
            <template v-else>
              <OvInput
                v-model="currentTenants[1].name"
                label="Behörig firmatecknare 2"
                placeholder="Behörig firmatecknare 2"
                description="Ange behörig firmatecknare 2"
                autocomplete="given-name"
              />
              <Fieldset>
                <OvInput
                  v-model="currentTenants[1].email"
                  label="Firmatecknarens 2 e-post"
                  placeholder="E-post"
                  description="Ange firmatecknarens 2 e-postadress"
                  type="email"
                  :validation="validEmail"
                  error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
                  autocomplete="email"
                />
                <OvInput
                  v-model="currentTenants[1].phone"
                  label="Firmatecknarens 2 telefonnummer"
                  placeholder="Telefonnummer"
                  description="Ange firmatecknarens 2 telefonnummer"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="tel"
                />
              </Fieldset>
              <OvInput
                v-model="currentTenants[1].address"
                label="Firmatecknarens 2 adress"
                placeholder="Firmatecknarens 2 adress"
                description="Ange firmatecknarens 2 adress"
              />
              <Fieldset>
                <OvPostalCodeSearchBar
                  v-model="currentTenants[1].postalCode"
                  :class="{ autocomplete: secondPostalCodeCompleted }"
                  label="Firmatecknarens postnummer"
                  placeholder="Firmatecknarens postnummer"
                  description="Ange firmatecknarens postnummer"
                  :validation="validatePostalCode"
                  error-text="Postnummer måste anges med endast 5 siffror"
                  @input="secondPostalCodeCompleted = false"
                  @dropdownMatch="postalCodeDropdownMatch($event, 1)"
                />
                <OvInput
                  v-model="currentTenants[1].city"
                  :class="{ autocomplete: secondPostalCodeCompleted }"
                  label="Firmatecknarens 2 ort"
                  placeholder="Firmatecknarens 2 ort"
                  description="Ange firmatecknarens 2 ort"
                  @input="secondPostalCodeCompleted = false"
                />
              </Fieldset>
            </template>
            <InfoModal
              v-if="confirmModalVisible"
              title="Är du säker?"
              :mobile-full-width="isMobile"
              :show-close-btn="false"
              confirm-label="Ta bort"
              :confirm-icon="false"
              abort-label="Avbryt"
              @click="confirmModalClick"
            >
              <p>Vill du ta bort medboenden?</p></InfoModal
            >
          </template>
        </ExpandableForm>
      </FieldsWrapper>
    </OvForm>
  </SubletPage>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePersonalNumber,
  validateOrganizationNumber,
  validatePostalCode
} from '@/utils/input-validations';
import { scrollExpandableForm } from '@/utils/scroll';
import { cloneTenant } from '@/utils/sublet';
import Fieldset from '@/components/Fieldset.vue';
import OvInput from '@/components/OvInput.vue';
import OvForm from '@/components/OvForm.vue';
import SubletPage from '@/components/SubletPage.vue';
import ExpandableForm from '@/components/ExpandableForm.vue';
import InfoModal from '@/components/InfoModal.vue';
import FieldsWrapper from '@/components/FieldsWrapper.vue';
import CompanyDetails from '@/components/CompanyDetails.vue';
import OvOrganizationSearch from '@/components/OvOrganizationSearch.vue';
import OvPostalCodeSearchBar from '@/components/OvPostalCodeSearchBar.vue';

export default {
  name: 'CurrentTenants',
  components: {
    Fieldset,
    OvInput,
    OvForm,
    SubletPage,
    ExpandableForm,
    InfoModal,
    FieldsWrapper,
    CompanyDetails,
    OvOrganizationSearch,
    OvPostalCodeSearchBar
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formExpanded: false,
      validEmail,
      validateNumber,
      validateOrganizationNumber,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      confirmModalVisible: false,
      formType: 'currentTenants',
      validatePostalCode,
      isSearchingPostalCode: false,
      postalCodeCompleted: false,
      secondPostalCodeCompleted: false
    };
  },
  computed: {
    ...mapState({
      companies: state => state.companies.companies
    }),
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'ovApplication/applicationIsEditable',
      rejectedCategories: 'ovApplication/rejectedCategories',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    activeCompanyDetails() {
      if (
        !this.validateOrganizationNumber(
          this.currentTenants[0]?.organizationNumber
        )
      ) {
        return {};
      }
      return this.companies[
        this.currentTenants[0].organizationNumber.split('-').join('')
      ];
    },
    currentTenants: {
      get() {
        return this.application?.currentTenants;
      },
      set() {
        this.$emit();
      }
    },
    currentTenantsCategoryRejected() {
      return this.rejectedCategories.includes('currentTenants');
    }
  },

  mounted() {
    if (this.currentTenants && this.currentTenants[1]) {
      this.formExpanded = true;
    }
  },
  methods: {
    ...mapActions({
      fetchCompany: 'companies/fetchCompany'
    }),
    postalCodeDropdownMatch(match, index) {
      this.$emit('isEditing', this.formType);
      this.currentTenants[index].postalCode = match.name;
      this.currentTenants[index].city = match.description;
      if (index === 0) {
        this.postalCodeCompleted = true;
      } else {
        this.secondPostalCodeCompleted = true;
      }
    },
    async confirmModalClick(eventType) {
      if (eventType === 'CONFIRM') {
        //this.currentTenants.pop();
        this.$delete(this.currentTenants, 1);
        this.emitEditing();
        this.formExpanded = false;
      }
      this.confirmModalVisible = false;
    },
    handleButtonClick() {
      const expand = !this.formExpanded;

      if (!expand && this.currentTenants[1]) {
        this.confirmModalVisible = true;
      } else {
        // Create second tenant if not exist
        this.$set(this.currentTenants, 1, cloneTenant(this.currentTenants));
        this.emitEditing();
        this.formExpanded = expand;
      }

      this.$nextTick(() => {
        this.scroll();
      });
    },
    scroll() {
      const formInput = this.$refs.expandableForm;
      const targetId = 'sticky-text-container';

      scrollExpandableForm(formInput, targetId);
    },
    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    }
  }
};
</script>
<style lang="scss" scoped>
.autocomplete :deep(input) {
  background-color: #e8f0fe;
}
.top-container {
  display: flex;
  align-items: center;

  .co-tenant-info-modal {
    margin-bottom: 0.3rem;
  }

  .text-info {
    padding: 1rem 0;
  }
}
</style>
