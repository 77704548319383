<template>
  <div>
    <InviteModal
      v-if="inviteModalVisible"
      :application-id="application.id"
      @close="handleInviteModalClose"
    />
    <InfoModal
      v-else-if="inviteOrContinueModalVisible"
      title="Bjud in övertagaren?"
      confirm-label="Bjud in"
      abort-label="Jag fortsätter själv"
      :is-disabled="isSaving"
      @click="handleInviteOrContinue"
    >
      <p>
        Du kan nu välja att antingen bjuda in den person som ska övertaga via
        e-post, eller så fortsätter du med ansökan på egen hand. Om du väljer
        att fortsätta utan att bjuda in någon just nu, kan du när som helst
        senare skicka en inbjudan genom att klicka på "Bjud in"-knappen uppe i
        headern
      </p>
    </InfoModal>
    <InfoModal
      v-if="confirmModalVisible"
      title="Spara ändringar?"
      confirm-label="Spara och fortsätt"
      abort-label="Spara inte"
      :is-disabled="isSaving"
      @click="confirmModalClick"
    >
      <p>
        Du har gjort ändringar på sidan. Vill du spara din ändringar innan du
        fortsätter?
      </p>
    </InfoModal>
    <InfoModal
      v-if="errorModalVisible"
      title="Något gick fel"
      abort-label="Stäng"
      @click="errorModalVisible = false"
    >
      <p>Det gick inte att spara dina ändringar, försök igen senare.</p>
    </InfoModal>
    <router-view
      v-if="clonedApplication"
      :application="clonedApplication"
      :saving-application-data="isSaving"
      :form-has-changed="formHasChanged"
      @subletFormSubmit="handleFormSubmit"
      @isEditing="handleEditingForm"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import InfoModal from '@/components/InfoModal.vue';
import InviteModal from '@/components/InviteModal.vue';

export default {
  name: 'OvManager',
  components: {
    InfoModal,
    InviteModal
  },
  beforeRouteUpdate(to, from, next) {
    //Leaving to new route without unmounting
    this.handleRouteLeave(next);
  },
  beforeRouteLeave(to, from, next) {
    //Leaving to new route and unmounting
    this.handleRouteLeave(next);
  },
  data() {
    return {
      formHasChanged: '',
      clonedApplication: null,
      isSaving: false,
      confirmModalVisible: false,
      errorModalVisible: false,
      goNextRoute: () => false,
      inviteOrContinueModalVisible: false,
      inviteModalVisible: false
    };
  },
  computed: {
    ...mapState({
      application: state => state.ovApplication.application,
      applicationId: state => state.ovApplication.applicationId
    }),
    ...mapGetters({
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    })
  },
  watch: {
    applicationId: {
      handler(newApplicationId, oldApplicationId) {
        if (newApplicationId !== oldApplicationId) {
          this.syncData();
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNativeLeave);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNativeLeave);
  },
  methods: {
    ...mapActions({
      editCurrentTenants: 'ovApplication/editCurrentTenants',
      editApartment: 'ovApplication/editApartment',
      editSecondaryTenants: 'ovApplication/editSecondaryTenants',
      editReason: 'ovApplication/editReason',
      goToNextCategory: 'ovApplication/goToNextCategory'
    }),
    async handleInviteOrContinue(event) {
      if (event === 'CONFIRM') {
        this.inviteModalVisible = true;
      } else {
        await this.handleFormSubmit();
        this.inviteOrContinueModalVisible = false;
      }
    },
    async handleInviteModalClose() {
      await this.handleFormSubmit();
      this.inviteModalVisible = false;
      this.inviteOrContinueModalVisible = false;
    },
    filterReadOnlyProps(category) {
      //  filter readOnly props, keep the rest
      //  eslint-disable-next-line
      const { rejectReason, status, ...rest } = category;
      return rest;
    },
    syncData() {
      this.clonedApplication = JSON.parse(JSON.stringify(this.application));
    },
    async confirmModalClick(eventType) {
      switch (eventType) {
        case 'CONFIRM':
          await this.handleFormSubmit();
          this.closeModalAndGoNext();
          break;
        case 'REJECT':
          this.syncData();
          this.formHasChanged = '';
          this.closeModalAndGoNext();
          break;
        default:
          this.confirmModalVisible = false;
      }
    },
    closeModalAndGoNext() {
      this.confirmModalVisible = false;
      this.goNextRoute();
    },
    handleRouteLeave(next = () => false) {
      if (this.formHasChanged) {
        this.confirmModalVisible = true;
        this.goNextRoute = next;
      } else {
        next();
      }
    },
    handleEditingForm(isEditing) {
      this.formHasChanged = isEditing;
    },
    async handleFormSubmit() {
      this.isSaving = true;
      try {
        switch (this.$route.name) {
          case 'currentTenants':
            await this.editCurrentTenants({
              applicationId: this.application.id,
              currentTenants: this.clonedApplication.currentTenants.map(
                currentTenant => this.filterReadOnlyProps(currentTenant)
              )
            });
            break;
          case 'apartment':
            await this.editApartment({
              applicationId: this.application.id,
              apartment: this.filterReadOnlyProps(
                this.clonedApplication.apartment
              )
            });
            break;
          case 'secondaryTenants':
            await this.editSecondaryTenants({
              applicationId: this.application.id,
              secondaryTenants: this.clonedApplication.secondaryTenants.map(
                secondaryTenant => this.filterReadOnlyProps(secondaryTenant)
              )
            });
            break;
          case 'reason':
            if (
              !this.inviteOrContinueModalVisible &&
              (this.isPrivateFlow
                ? this.clonedApplication.reason.type &&
                  !this.application.reason.type
                : this.clonedApplication.reason.text &&
                  !this.application.reason.text)
            ) {
              this.inviteOrContinueModalVisible = true;
              this.isSaving = false;
              return;
            }
            await this.editReason({
              applicationId: this.application.id,
              reason: this.filterReadOnlyProps(this.clonedApplication.reason)
            });
            break;
          default:
            console.log('No match for', this.$route.name);
        }
        this.syncData();
        this.formHasChanged = '';
        await this.goToNextCategory(this.$router);
      } catch (err) {
        console.error(err);
        this.errorModalVisible = true;
      }
      this.isSaving = false;
    },
    preventNativeLeave(event) {
      if (!this.formHasChanged) {
        return;
      }
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }
};
</script>

<style></style>
