<template>
  <transition
    name="expand"
    @before-enter="beforeEnter"
    @enter="enter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <div
      v-if="companyDetails && !!Object.keys(companyDetails).length"
      class="company-container"
    >
      <Fieldset
        v-if="
          companyDetails.ceo ||
          companyDetails.chairman ||
          companyDetails.boardMember
        "
      >
        <OvInput :value="companyDetails.name" label="Namn" :disabled="true" />
        <OvInput
          v-if="companyDetails.ceo"
          :value="companyDetails.ceo"
          label="Verkställande direktör"
          :disabled="true"
        />
        <OvInput
          v-else-if="companyDetails.chairman"
          :value="companyDetails.chairman"
          label="Ordförande"
          :disabled="true"
        />
        <OvInput
          v-else-if="companyDetails.boardMember"
          :value="companyDetails.boardMember"
          label="Ledamot"
          :disabled="true"
        />
      </Fieldset>
      <Fieldset>
        <OvInput :value="companyDetails.name" label="Namn" :disabled="true" />
        <OvInput
          :value="
            new Date(companyDetails.registrationDate).toLocaleDateString(
              'sv-SE'
            )
          "
          label="Registreringsdatum"
          :disabled="true"
      /></Fieldset>
      <OvInput
        :value="companyDetails.address"
        label="Adress"
        :disabled="true"
      />
      <OvInput
        v-for="(sni, i) in filteredSnis"
        :key="sni.kod"
        :value="`${sni.kod} ${sni.klartext}`"
        :label="`SNI-branch (${i + 1})`"
        :disabled="true"
      />

      <!-- <Fieldset>
        <OvInput
          :value="
            new Date(companyDetails.registrationDate).toLocaleDateString(
              'sv-SE'
            )
          "
          label="Registreringsdatum"
          :disabled="true"
        />
        <OvInput
          :value="companyDetails.employeesAmount"
          label="Antal anställda"
          :disabled="true"
        />
      </Fieldset> -->
      <OvInput
        :value="companyDetails.activities"
        type="textarea"
        :disabled="true"
        label="Verksamhet & ändamål"
      />
    </div>
  </transition>
</template>
<script>
import Fieldset from '@/components/Fieldset.vue';
import OvInput from '@/components/OvInput.vue';

export default {
  name: 'CompanyDetails',
  components: {
    Fieldset,
    OvInput
  },
  props: {
    companyDetails: {
      type: Object,
      default: null
    }
  },
  computed: {
    filteredSnis() {
      return this.companyDetails.sni.filter(sni => !!sni.klartext);
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.offsetHeight;
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave(el) {
      el.offsetHeight;
      el.style.height = 0;
    }
  }
};
</script>
<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.3s ease;
  overflow: hidden;
}

.company-container > *:nth-last-child(1) {
  margin-bottom: 0;
}
</style>
