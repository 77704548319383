<template>
  <SwapDefaultPage
    :possessions="
      !useSplitDocumentUpload('INCOME_BASIS')
        ? []
        : getMembersListForTab(applicant, selectedPossessionIndex)
    "
    @applicantClick="handleApplicantClick"
    @possessionClick="setSelectedPossessionIndex"
  >
    <RejectionMessageCardDocs
      type="INCOME_BASIS"
      :invalid-documents="invalidDocuments"
      :is-new-rejection="isNewRejection"
    />
    <CollapseCard v-if="nextApplicantHasSkandia" :is-alert="true">
      <h3 slot="title-text">Särskild information om Skandia</h3>
      <template slot="body-text">
        <p>
          Utöver de tre senaste lönespecifikationerna kräver Skandia ett
          <b><i>anställningsintyg</i></b
          >. Kontakta din arbetsgivare och be om ett uppdaterat och signerat
          anställningsintyg som påvisar att du har en pågående tjänst.
          <i><b>(Anställningsavtalet är inte tillräckligt)</b></i>
        </p>
      </template>
    </CollapseCard>
    <CollapseCard
      :closed-identifier="'inc_basis_modal_closed'"
      :is-primary="true"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <p>
          Här laddar du upp underlag på din löpande inkomst. Det som krävs är
          ett av behörig underskrivet
          <strong
            >anställningsavtal samt de 3 senaste lönespecifikationerna.</strong
          >
        </p>
        <br />
        <h3>Inkomstdeklaration gäller ej som inkomstunderlag.</h3>
        <br />
        <p>
          <strong>
            föräldraledighet / Försäkringskassan, A-kassa, CSN, pension m.m.
            gäller också som inkomst
          </strong>
          och då bifogas liknande underlag i form av utbetalningar, beslut m.m.
        </p>
        <br />
        <p>
          <strong>Som egenföretagare</strong> (Enskild firma) behövs balans-
          samt resultat-rapport.
        </p>
        <br />
        <p>
          Som egenföretagare (Aktiebolag) gäller samma som för Enskild firma och
          i de fall det finns en revisor bifogas med fördel även ett intyg på
          löneutbetalningar från denne. Besparingar räknas inte som löpande
          inkomst.
        </p>
        <br />
        <p>
          Ibland händer det att någon av bytesparterna har ett
          <strong>betalningsföreläggande</strong> och i vissa fall kan detta
          vara okej hos hyresvärdarna och i vissa fall inte. Det är därför
          <strong>viktigt att DU som bytespart informerar oss om detta</strong>
          i ett så tidigt skede som möjligt i processen så att vi kan kika
          närmare på detta innan vi skickar in bytesansökan.
        </p>
        <p>
          Det går bra att skicka dokument som är inscannade så väl som
          fotokopior
        </p>
        <br />
        <p><strong>Tänk på att:</strong></p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          Tips: För att fota dokument på bästa sätt rekommenderar vi att du
          använder dig av en app som scannar av dokument. Exempel på appar som
          fungerar bra till både iOS och Android är Adobe Scan och Genius Scan,
          båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a :href="`mailto:${getSupportAddress}`" target="_blank">
            {{ getSupportAddress }}
          </a>
        </p>
      </template>
    </CollapseCard>

    <SwapDocumentUpload
      type="INCOME_BASIS"
      :invalid-documents="invalidDocuments"
    ></SwapDocumentUpload>
  </SwapDefaultPage>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { getInvalidDocuments, getMembersListForTab } from '@/utils/common';

import SwapDocumentUpload from '@/components/SwapDocumentUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import RejectionMessageCardDocs from '@/components/RejectionMessageCardDocs.vue';

export default {
  name: 'IncomeBasis',

  components: {
    SwapDocumentUpload,
    CollapseCard,
    SwapDefaultPage,
    RejectionMessageCardDocs
  },
  data() {
    return {
      getMembersListForTab
    };
  },
  computed: {
    ...mapGetters({
      applicant: 'application/applicant',
      getSupportAddress: 'app/getSupportAddress',
      useSplitDocumentUpload: 'application/useSplitDocumentUpload',
      nextApplicantHasSkandia: 'application/nextApplicantHasSkandia'
    }),
    ...mapState({
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      application: state => state.application.application,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    invalidDocuments() {
      if (!this.isNewRejection) {
        return {};
      }
      const documents =
        this.applicant?.documentGroups[this.selectedPossessionIndex]
          .INCOME_BASIS.documents || [];
      return getInvalidDocuments(documents);
    },
    isNewRejection() {
      return !!this.applicant?.newRejections.documentGroups[
        this.selectedPossessionIndex
      ].INCOME_BASIS;
    }
  },
  methods: {
    ...mapMutations({
      setSelectedApplicantIndex: 'application/setSelectedApplicantIndex',
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    handleApplicantClick(index) {
      this.setSelectedApplicantIndex(index);
      this.setSelectedPossessionIndex(0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>
<style></style>
